import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import ToggleColorMode from "./ToggleColorMode";
import LanguageSwitch from "./LanguageSwitch"; // Import LanguageSwitch component
import logo from "../../assets/orthotics-center.jpg";

const logoStyle = {
  width: "40px",
  borderRadius: "10%",
  marginLeft: "15px",
  height: "auto",
  cursor: "pointer",
};
function AppAppBar({ mode, toggleColorMode, t }) {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
      setOpen(false);
    }
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: "999px",
              bgcolor:
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)",
              backdropFilter: "blur(24px)",
              maxHeight: 40,
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                ml: "-18px",
                px: 0,
              }}
            >
              <Link href="https://orthoticscenter-4637c.web.app/">
                <img src={logo} style={logoStyle} alt="logo of sitemark" />
              </Link>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <MenuItem
                  sx={{ py: "6px", px: "12px" }}
                  onClick={() => scrollToSection("about-us")}
                >
                  <Typography variant="body2" color="text.primary">
                    {t("appBar.aboutUs")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection("footwear")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    {t("appBar.footwear")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection("spinalCare")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    {t("appBar.spinalCare")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection("upperLower")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    {t("appBar.upperLowerExtremity")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  sx={{ py: "6px", px: "12px" }}
                  onClick={() => scrollToSection("prosthetics")}
                >
                  <Typography variant="body2" color="text.primary">
                    {t("appBar.prosthetics")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection("referrals")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    {t("appBar.referrals")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection("healthCare")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    {t("appBar.healthCare")}
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 2,
                alignItems: "center",
              }}
            >
              <LanguageSwitch />
            </Box>
            <Box sx={{ display: { sm: "", md: "none" } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: "30px", p: "4px" }}
              >
                <MenuIcon />
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            minWidth: "60dvw",
            p: 2,
            backgroundColor: "background.paper",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              flexGrow: 1,
              gap: 1,
            }}
          ></Box>
          <MenuItem onClick={() => scrollToSection("about-us")}>
            {t("appBar.aboutUs")}
          </MenuItem>
          <MenuItem onClick={() => scrollToSection("footwear")}>
            {t("appBar.footwear")}
          </MenuItem>
          <MenuItem onClick={() => scrollToSection("spinalCare")}>
            {t("appBar.spinalCare")}
          </MenuItem>
          <MenuItem onClick={() => scrollToSection("upperLower")}>
            {t("appBar.upperLowerExtremity")}
          </MenuItem>
          <MenuItem onClick={() => scrollToSection("prosthetics")}>
            {t("appBar.prosthetics")}
          </MenuItem>
          <MenuItem onClick={() => scrollToSection("referrals")}>
            {t("appBar.referrals")}
          </MenuItem>
          <MenuItem onClick={() => scrollToSection("healthCare")}>
            {t("appBar.healthCare")}
          </MenuItem>
          <Divider />
          <LanguageSwitch />
        </Box>
      </Drawer>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  toggleLanguage: PropTypes.func.isRequired,
};

export default AppAppBar;
