import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ElbowImage from "../../assets/elbow.webp";
import WristImage from "../../assets/wrist.webp";
import ShoulderImage from "../../assets/shoulder.webp";
import KneeImage from "../../assets/knee.webp";
import HipImage from "../../assets/hip.webp";
import AnkleImage from "../../assets/ankle.webp";

const generateDescriptionKeys = (itemIndex, descriptionCount) => {
  const descriptionKeys = [];
  for (let i = 0; i < descriptionCount; i++) {
    descriptionKeys.push(
      `upperLowerExtremity.items.${itemIndex}.description.${i}`
    );
  }
  return descriptionKeys;
};

const items = [
  {
    titleKey: "upperLowerExtremity.items.0.title",
    descriptionKeys: generateDescriptionKeys(0, 2),
    image: WristImage,
  },
  {
    titleKey: "upperLowerExtremity.items.1.title",
    descriptionKeys: generateDescriptionKeys(1, 1),
    image: ElbowImage,
  },
  {
    titleKey: "upperLowerExtremity.items.2.title",
    descriptionKeys: generateDescriptionKeys(2, 3),
    image: ShoulderImage,
  },
  {
    titleKey: "upperLowerExtremity.items.3.title",
    descriptionKeys: generateDescriptionKeys(3, 2),
    image: AnkleImage,
  },
  {
    titleKey: "upperLowerExtremity.items.4.title",
    descriptionKeys: generateDescriptionKeys(4, 2),
    image: KneeImage,
  },
  {
    titleKey: "upperLowerExtremity.items.5.title",
    descriptionKeys: generateDescriptionKeys(5, 2),
    image: HipImage,
  },
];

export default function UpperLowerExtremity({ t }) {
  return (
    <Box
      id="upperLower"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: "white",
        bgcolor: "#06090a",
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4">
            {t("upperLowerExtremity.title")}
          </Typography>
          <Typography variant="body1" sx={{ color: "grey.400" }}>
            {t("upperLowerExtremity.message")}
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: "100%",
                  border: "1px solid",
                  borderColor: "grey.800",
                  background: "transparent",
                  backgroundColor: "grey.900",
                }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={item.image}
                  alt={t(item.titleKey)}
                />
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {t(item.titleKey)}
                  </Typography>
                  {item.descriptionKeys.map((descKey, i) => (
                    <Typography
                      key={i}
                      variant="body2"
                      sx={{ color: "grey.400" }}
                    >
                      {t(descKey)}
                    </Typography>
                  ))}
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
