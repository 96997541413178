import * as React from "react";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function AboutUsScreen({ t }) {
  return (
    <Container
      id="about-us"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt: { xs: 14, sm: 20 },
        pb: { xs: 8, sm: 12 },
      }}
    >
      <Box
        sx={{
          width: "100%",
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <Box
              sx={(theme) => ({
                background: theme.palette.background.paper,
                borderRadius: "10px",
                padding: "20px",
                boxShadow:
                  theme.palette.mode === "light"
                    ? `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`
                    : `0 0 24px 12px ${alpha("#033363", 0.2)}`,
                maxWidth: "800px",
                width: "100%",
                margin: "auto",
              })}
            >
              <Typography
                component="h2"
                variant="h4"
                color="text.primary"
                gutterBottom
              >
                {t("aboutUs.title")}
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                {t("aboutUs.message")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={(theme) => ({
                background: theme.palette.background.paper,
                borderRadius: "10px",
                padding: "20px",
                boxShadow:
                  theme.palette.mode === "light"
                    ? `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`
                    : `0 0 24px 12px ${alpha("#033363", 0.2)}`,
                maxWidth: "800px", // Adjust the maximum width here
                width: "100%",
                margin: "auto",
              })}
            >
              <Typography
                component="h2"
                variant="h4"
                color="text.primary"
                gutterBottom
              >
                {t("aboutUs.missionStatement")}
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                {t("aboutUs.missionStatementMessage")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
