import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Masonry from "@mui/lab/Masonry";
import { useMediaQuery } from "@mui/material";
import cervical from "../../assets/cervical.jpeg";
import sacroliliac from "../../assets/sacroliliac.jpeg";
import LSOImage from "../../assets/LSO.png";
import TLSOImage from "../../assets/TLSO.png";
import CTOImage from "../../assets/CTO-image.png";

const spinalCard = [
  {
    nameKey: "spinalCare.spinalCard.0.name",
    occupation: "CO",
    image: cervical,
  },
  {
    nameKey: "spinalCare.spinalCard.1.name",
    occupation: "CTO",
    image: CTOImage,
  },
  {
    nameKey: "spinalCare.spinalCard.2.name",
    occupation: "TLSO",
    image: TLSOImage,
  },
  {
    nameKey: "spinalCare.spinalCard.3.name",
    occupation: "LSO",
    image: LSOImage,
  },
  {
    nameKey: "spinalCare.spinalCard.4.name",
    occupation: "SIO",
    image: sacroliliac,
  },
];

export default function SpinalCare({ t }) {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const columns = isSmallScreen ? 1 : 2;

  return (
    <Container
      id="spinalCare"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          {t("spinalCare.title")}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {t("spinalCare.message")}
        </Typography>
      </Box>
      <Masonry columns={columns} spacing={2}>
        {spinalCard.map((spinalKey, index) => (
          <Card
            key={index}
            sx={{ p: 1, display: "flex", alignItems: "center" }}
          >
            <CardMedia
              component="img"
              height="200"
              maxWidth="100%"
              image={spinalKey.image}
              sx={{ objectFit: "contain" }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                pr: 2,
              }}
            >
              <CardHeader
                title={t(spinalKey.nameKey)}
                subheader={spinalKey.occupation}
                style={{ textAlign: "center" }}
              />
            </Box>
          </Card>
        ))}
      </Masonry>
    </Container>
  );
}
