import * as React from "react";
import PropTypes from "prop-types";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AppAppBar from "./components/AppAppBar";
import Hero from "./components/Hero";
import UpperLowerExtremity from "./components/UpperLower";
import Referrals from "./components/Referrals";
import Footwear from "./components/Footwear";
import SpinalCare from "./components/SpinalCare";
import HealthCare from "./components/HealthCare";
import AboutUsScreen from "./components/AboutUsScreen";
import Prosthetics from "./components/Prosthetics";
import Footer from "./components/Footer";
import getLPTheme from "./getLPTheme";
import { useTranslation } from "react-i18next";

const defaultTheme = createTheme({});

function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "fixed",
        bottom: 24,
      }}
    ></Box>
  );
}

ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.shape({
    valueOf: PropTypes.func.isRequired,
  }).isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

export default function LandingPage() {
  const [mode, setMode] = React.useState("dark");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode));

  const { t } = useTranslation("global");

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} t={t} />
      <Hero t={t} />
      <Box sx={{ bgcolor: "background.default" }}>
        <Divider />
        <AboutUsScreen t={t} />
        <Divider />
        <Footwear t={t} />
        <Divider />
        <SpinalCare t={t} />
        <Divider />
        <UpperLowerExtremity t={t} />
        <Divider />
        <Prosthetics t={t} />
        <Divider />
        <Referrals t={t} />
        <Divider />
        <HealthCare t={t} />
        <Divider />
        <Footer />
      </Box>
      <ToggleCustomTheme
        showCustomTheme={showCustomTheme}
        toggleCustomTheme={toggleCustomTheme}
      />
    </ThemeProvider>
  );
}
