import * as React from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import medica from "../../assets/medica.jpeg";
import carePlus from "../../assets/care-plus.jpeg";
import floridaBlue from "../../assets/florida-blue.png";
import molina from "../../assets/molina-healthCare.jpeg";

export default function HealthCare({ t }) {
  const logoImages = [medica, molina, floridaBlue, carePlus];

  return (
    <Container
      id="healthCare"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        {t("healthCare.title")}
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Typography component="h3" variant="subtitle2">
          {t("healthCare.message")}
        </Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: 1 }}>
            <Typography component="h3" variant="subtitle2">
              - Aetna Medicare Open Plan
              <br />
              - Allwell
              <br />
              - Ambetter
              <br />
              - Bright Health
              <br />
              - Careplus
              <br />
              - Devoted Healthcare
              <br />
              - Doctors
              <br />
              - Florida Blue
              <br />
              - Florida Health Choice PPO
              <br />
              - HealthSun Health Plans
              <br />
              - Humana Medicare
              <br />
              - Medicaid
              <br />
              - Medicare
              <br />
            </Typography>
          </div>
          <div style={{ flex: 1 }}>
            <Typography component="h3" variant="subtitle2">
              - MMM of Florida
              <br />
              - Molina Healthcare
              <br />
              - Oscar
              <br />
              - Preferred
              <br />
              - Preferred Care Partners
              <br />
              - Simply Medicaid
              <br />
              - Simply Medicare
              <br />
              - Solis
              <br />
              - Staywell
              <br />
              - Sunshine Health
              <br />
              - United Healthcare (UHC) PPO (NO MEDICAID)
              <br />
              - WellCare Health Plan
              <br />- WPS Tricare
            </Typography>
          </div>
        </div>

        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Grid container spacing={2}>
            {logoImages.map((image, index) => (
              <Grid item key={index} xs={6} sm={3}>
                <img
                  src={image}
                  alt={`Logo ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "fill",
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
