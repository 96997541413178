import React, { useState } from "react";
import PropTypes from "prop-types";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

function LanguageSwitch() {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const handleChange = () => {
    const newLanguage = language === "en" ? "es" : "en";
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  return (
    <FormControlLabel
      control={<Switch checked={language === "es"} onChange={handleChange} />}
      label={
        <Typography>{language === "en" ? "English" : "Español"}</Typography>
      }
    />
  );
}

LanguageSwitch.propTypes = {
  defaultLanguage: PropTypes.oneOf(["en", "es"]).isRequired,
};

export default LanguageSwitch;
