import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import prosthetic1 from "../../assets/Prosthetic1.png";
import prosthetic2 from "../../assets/Prosthetic2.png";
import prosthetic3 from "../../assets/Prosthetic3.jpg";

const items = [
  {
    titleKey: "prosthetics.items.0.title",
    descriptionKey: "prosthetics.items.0.description",
  },
  {
    titleKey: "prosthetics.items.1.title",
    descriptionKey: "prosthetics.items.1.description",
  },
  {
    titleKey: "prosthetics.items.2.title",
    descriptionKey: "prosthetics.items.2.description",
  },
  {
    titleKey: "prosthetics.items.3.title",
    descriptionKey: "prosthetics.items.3.description",
  },
  {
    titleKey: "prosthetics.items.4.title",
    descriptionKey: "prosthetics.items.4.description",
  },
];

const images = [prosthetic1, prosthetic2, prosthetic3];

const Prosthetics = ({ t }) => {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  return (
    <Container id="prosthetics" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ textAlign: { sm: "left", md: "center" } }}
        >
          <div>
            <Typography component="h2" variant="h4" color="text.primary">
              {t("prosthetics.title")}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              {t("prosthetics.message")}
            </Typography>
          </div>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Prosthetic ${index + 1}`}
                style={{ width: "35%", borderRadius: "10px" }}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            {items.map((item, index) => (
              <Card key={index} variant="outlined" sx={{ mb: 2 }}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="h6" color="text.primary" gutterBottom>
                    {t(item.titleKey)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t(item.descriptionKey)}
                  </Typography>
                </Box>
              </Card>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Prosthetics;
